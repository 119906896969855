/* You can add global styles to this file, and also import other style files */

@use '@angular/material' as mat;
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");
// Light theme configuration
$theme: mat.define-theme(
    (
       color: (
           theme-type: light,
           primary: mat.$blue-palette,
        ),
    )
);

:root {
  @include mat.all-component-themes($theme);

  --mon-red: #db332a;
  --mon-red-light: #ffdfdd;
  
  --mon-gray : #415273;
  --mon-gray-light : #6f7a92;
  --mon-black-light:  #6f717594;;

  --mon-green: #417352;
  --mon-green-light : #448f5d;

  --mon-orange : #db802a;

  --mon-yellow : #ffdb00;
  
  --mon-white-light : #f3f0eb;
  --mon-white-light2 :rgb(240, 240, 240);
  
}

@include mat.color-variants-backwards-compatibility($theme);
@include mat.typography-hierarchy($theme);


html, body { height: 100%; }
body { margin: 0; font-family: Source Sans Pro, sans-serif; }

